<template>
  <v-app>
    <v-container v-if="!token">
      <v-form @submit.prevent="login">
        <v-text-field label="Password" v-model="password" type="password" :error-messages="errors"
                      @input="errors=[]" autofocus/>

        <v-btn class="ml-0 mt-4" primary type="submit">Login</v-btn>
      </v-form>
    </v-container>
    <upload-form v-if="token" :token="token"/>
  </v-app>
</template>

<script>
import UploadForm from './components/UploadForm';
import { decode } from 'jsonwebtoken';

export default {
  name: 'app',
  components: { UploadForm },
  data: () => ({
    password: '',
    errors: [],
    token: ''
  }),
  methods: {
    async login() {
      try {
        const response = await this.$axios.post('/login', {
          password: this.password
        });
        sessionStorage.setItem('token', response.data.token);
        this.token = response.data.token;
      }
      catch (e) {
        console.log(e);
        this.errors = ['Invalid password']
      }
    }
  },
  async mounted() {
    this.token = sessionStorage.getItem('token');
    const { exp } = (await decode(this.token)) || {};
    if (!exp || exp * 1000 < Date.now()) {
      this.token = null
    }
  }
}
</script>
<style>
html, body {
  height: 100%;
}
</style>
