var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{'upload-form': true, dragging: _vm.dragging},on:{"drop":function($event){$event.preventDefault();return _vm.drop($event)},"dragover":function($event){$event.preventDefault();return _vm.dragover($event)},"dragenter":function($event){$event.preventDefault();_vm.dragging++},"dragleave":function($event){$event.preventDefault();_vm.dragging--}}},[_c('v-container',[_c('v-btn',{staticClass:"ml-0",attrs:{"tag":"div"}},[_vm._v(" Select files "),_c('input',{ref:"file",attrs:{"type":"file","multiple":""},on:{"change":_vm.selectedFiles}})]),(_vm.error)?_c('v-btn',{staticClass:"ml-3",attrs:{"disabled":_vm.isUploading},on:{"click":_vm.upload}},[_vm._v("Retry")]):_vm._e(),_c('div',{staticClass:"mt-3"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.uploading,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.file.size",fn:function(ref){
var file = ref.item.file;
return [_vm._v(" "+_vm._s(_vm._f("byteSize")(file.size))+" ")]}},{key:"item.status",fn:function(ref){
var status = ref.item.status;
return [(status === 'uploading')?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"info","size":20,"width":2}}):_vm._e(),_vm._v(" "+_vm._s(status)+" ")]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"height":"10","value":_vm._f("completeness")(item),"color":_vm._f("color")(item)}})]}},{key:"item.eta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("eta")(item))+" ")]}},{key:"item.actions",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(item.status !== 'done')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.remove(index)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }