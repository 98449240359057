import Vue from 'vue'
import App from './App.vue'
import '@/plugins/axios';
import vuetify from '@/plugins/vuetify';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import en from 'dayjs/locale/en';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.locale(en);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  render: h => h(App),
}).$mount('#app');
