<template>
  <div :class="{'upload-form': true, dragging}" @drop.prevent="drop" @dragover.prevent="dragover"
       @dragenter.prevent="dragging++" @dragleave.prevent="dragging--">
    <v-container>
      <v-btn tag="div" class="ml-0">
        Select files
        <input type="file" multiple @change="selectedFiles" ref="file">
      </v-btn>
      <v-btn :disabled="isUploading" v-if="error" @click="upload" class="ml-3">Retry</v-btn>
      <div class="mt-3">
        <v-data-table
          :headers="headers"
          :items="uploading"
          class="elevation-1"
          hide-default-footer
          disable-sort
        >
          <template #item.file.size="{ item: { file } }">
            {{ file.size | byteSize }}
          </template>
          <template #item.status="{ item: { status } }">
            <v-progress-circular
                v-if="status === 'uploading'"
                indeterminate
                color="info"
                :size="20"
                :width="2"
            />
            {{ status }}
          </template>
          <template #item.progress="{ item }">
            <v-progress-linear
              height="10"
              :value="item | completeness"
              :color="item | color"
            />
          </template>
          <template #item.eta="{ item }">
            {{ item | eta }}
          </template>
          <template #item.actions="{ index, item }">
            <v-btn
              v-if="item.status !== 'done'"
              icon
              @click="remove(index)"
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </div>
</template>

<script>
import byteSize from 'byte-size';
import dayjs from 'dayjs';
import axios from 'axios';

export default {
  name: 'UploadForm',
  data: () => ({
    uploading: [],
    headers: [
      { text: 'Status', value: 'status', width: '80px', align: 'left', cellClass: 'text-no-wrap' },
      { text: 'Progress', value: 'progress', width: '200px' },
      { text: 'Size', value: 'file.size', cellClass: 'text-no-wrap' },
      { text: 'ETA', value: 'eta', cellClass: 'text-no-wrap' },
      { text: 'Name', value: 'file.name', width: '100%' },
      { text: '', value: 'actions' }
    ].map(item => ({ sortable: false, ...item })),
    isUploading: false,
    error: false,
    dragging: false
  }),
  filters: {
    byteSize,
    completeness(item) {
      return item.progress / item.file.size * 100;
    },
    color(item) {
      switch (item.status) {
        case 'queued':
          return 'warning';
        case 'uploading':
          return 'info';
        case 'done':
          return 'success';
        case 'error':
          return 'error';
      }
    },
    eta(item) {
      if (!item.started || item.status !== 'uploading') {
        return '';
      }

      const diff = dayjs().diff(item.started) / item.progress * item.file.size;

      if (Number.isNaN(diff) || !Number.isFinite(diff)) {
        return ''
      }

      const duration = dayjs.duration(diff);

      return duration.humanize();
    }
  },
  props: {
    token: String
  },
  methods: {
    addFiles(files) {
      const filesArray = Array.from(files);
      const fileIsEqual = (a, b) => a.name === b.name && a.lastModified === b.lastModified;

      this.uploading = [
        ...this.uploading.map(({ file, ...rest }) => ({
          ...rest,
          file: filesArray.find(source => fileIsEqual(file, source)) || file
        })),
        ...filesArray
        .filter((file) => !this.uploading.find((item) => fileIsEqual(file, item.file)))
        .map(file => ({
          file,
          status: 'queued',
          progress: 0
        }))
      ];

      this.persist();
    },

    async selectedFiles() {
      this.addFiles(this.$refs.file.files);
      this.$refs.file.value = '';
      await this.upload();
    },

    async uploadFile(item) {
      let offset = 0;
      const headers = {
        authorization: `Bearer ${this.token}`
      };

      try {
        offset = (await this.$axios.get(`/upload/size/${item.file.name}`, {
          headers
        })).data;
      }
      catch (e) {
        if (e.response?.status !== 404) {
          item.status = 'stalled';
          throw e;
        }
        offset = 0;
      }

      item.status = 'uploading';
      item.started = dayjs();

      if (offset !== item.file.size) {

        this.currentlyUploading = item.file;
        this.cancelToken = axios.CancelToken.source()
        try {
          await this.$axios.put(`/upload/${item.file.name}`, item.file.slice(offset), {
            headers,

            params: {
              offset
            },

            onUploadProgress(e) {
              item.progress = e.loaded + offset;
            },
            cancelToken: this.cancelToken.token
          });

          item.progress = item.file.size;
          item.status = 'done';
        }
        catch (e) {
          item.status = 'stalled';
          throw e;
        }
        finally {
          this.currentlyUploading = null;
          this.cancelToken = null;
        }
      } else {
        item.progress = item.file.size;
        item.status = 'done';
      }
    },

    async upload() {
      const uploading = this.uploading.find(item => item.status === 'uploading');
      const item = this.uploading.find(item => item.status === 'queued' || item.status === 'error');
      if (!item || uploading) {
        return;
      }

      this.isUploading = true;

      let done = false;
      let tries = 10;
      let cancelled = false
      do {
        try {
          await this.uploadFile(item);
          done = true;
        }
        catch (e) {
          console.warn(e);
          if (e?.message === 'cancelled') {
            cancelled = true
          } else {
            await new Promise(resolve => setTimeout(resolve, 1000));
          }
        }
        tries--;
      } while(!done && tries > 0 && !cancelled)

      this.isUploading = false;
      this.error = !done;
      if (!done) {
        item.status = 'error'
      }

      this.persist();

      // upload next file
      if (done) {
        this.$nextTick(() => this.upload());
      }
    },

    async drop(e) {
      this.dragging = 0;
      this.addFiles(e.dataTransfer.files);
      await this.$nextTick();
      await this.upload();
    },

    dragover(e) {
      e.dataTransfer.dropEffect = 'copy';
    },

    remove(index) {
      if (this.currentlyUploading === this.uploading[index].file) {
        console.log('Cancel currently uploading')
        this.cancelToken.cancel('cancelled');
      }

      this.uploading.splice(index, 1)
    },

    persist () {
      sessionStorage.setItem('uploads', JSON.stringify(
        this.uploading.map(({ file, ...rest }) => ({
          ...rest,
          file: {
            name: file.name,
            size: file.size,
            lastModified: file.lastModified
          }
        }))
      ));
    }
  },
  mounted() {
    let uploading;
    try {
      uploading = JSON.parse(sessionStorage.getItem('uploads'))
    }
    catch (e) {
      // ignore
    }

    if (uploading) {
      this.uploading = uploading.map(({ status, ...rest }) => ({
        ...rest,
        status: status === 'done' ? 'done': 'error'
      }))
    }
  }
}
</script>

<style scoped>
.v-btn {
  position: relative;
}

>>> .v-btn__content {
  position: static;
}

[type=file] {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  margin-left: -140px;
  width: calc(140px + 100%);
}

.upload-form {
  height: 100%;
}

.dragging {
  background: #d3d3d3;
}
</style>
